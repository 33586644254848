var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coupon-center"},[_c('div',{staticClass:"search-bar"},[_c('van-search',{staticClass:"my-search",attrs:{"background":"transparent","placeholder":"输入关键词/商品名称"},on:{"click":_vm.toSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_vm._m(0)],1),_c('div',{staticClass:"hot-goods"},[_c('div',{staticClass:"hot-content"},[_c('img',{staticClass:"coupon-title-icon",attrs:{"src":require("../assets/images/coupon-title-icon.png"),"alt":""}}),_c('div',{staticClass:"goods-list"},[_c('div',{staticClass:"list-content"},[(_vm.listType == 1)?_c('div',{staticClass:"each-div"},[_c('div',{staticClass:"top-coupon"},[_c('div',{staticClass:"goods-price"},[_c('span',{staticStyle:{"font-size":"0.28rem","padding-top":"0.1rem","margin-right":"0.04rem"}},[_vm._v("¥")]),_c('span',[_vm._v(_vm._s(_vm.limitCouponList[0].amount))])]),_c('div',{staticClass:"goods-spec"},[_c('div',{staticClass:"names"},[_vm._v(" "+_vm._s(_vm.limitCouponList[0].couponName)+" ")]),_c('div',{staticClass:"spec"},[_vm._v("指定规格属性可用")])]),_c('div',{class:[
                  'isGet',
                  _vm.limitCouponList[0].reviceStatus == 40 && 'isUse' ],on:{"click":function($event){return _vm.takeCoupon(_vm.limitCouponList[0])}}},[_vm._v(" "+_vm._s(_vm.couponStatus[_vm.limitCouponList[0].reviceStatus])+" ")])]),_c('div',{staticClass:"other-coupon"},_vm._l((_vm.limitCouponList.slice(1, 4)),function(item){return _c('div',{key:item.batchId,staticClass:"coupon-item"},[_c('div',{staticClass:"tag"},[_vm._v("限时钜惠")]),_c('div',{staticClass:"item-top"},[(item.reviceStatus == 20)?_c('img',{staticClass:"get-icon",attrs:{"src":require("../assets/images/water_mark1.png"),"alt":""}}):_vm._e(),(item.reviceStatus == 40)?_c('img',{staticClass:"get-icon",attrs:{"src":require("../assets/images/used2.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"price-box"},[_c('span',{staticClass:"unit"},[_vm._v("￥")]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(item.amount))])]),_c('div',{staticClass:"coupon-name one-line-hidden"},[_vm._v(" "+_vm._s(item.couponName)+" ")]),_c('div',{staticClass:"coupon-tip"},[_vm._v("指定规格属性可用")])]),_c('div',{staticClass:"item-bottom"},[_c('span',{class:[
                      'action-btn',
                      item.reviceStatus == 40 && 'isUse' ],on:{"click":function($event){return _vm.takeCoupon(item)}}},[_vm._v(_vm._s(_vm.couponStatus[item.reviceStatus]))])])])}),0)]):_vm._e(),(_vm.listType == 2)?_c('div',{staticClass:"each-div"},_vm._l((_vm.limitCouponList),function(item){return _c('div',{key:item.batchId,staticClass:"top-coupon list-type-2"},[_c('div',{staticClass:"goods-price"},[_c('span',{staticClass:"unit"},[_vm._v("¥")]),_c('span',[_vm._v(_vm._s(item.amount))])]),_c('div',{staticClass:"goods-spec"},[_c('div',{staticClass:"names"},[_vm._v(" "+_vm._s(item.couponName)+" ")]),_c('div',{staticClass:"spec"},[_vm._v("指定规格属性可用")])]),_c('div',{class:['isGet', item.reviceStatus == 40 && 'isUse'],on:{"click":function($event){return _vm.takeCoupon(item)}}},[_vm._v(" "+_vm._s(_vm.couponStatus[item.reviceStatus])+" ")])])}),0):_vm._e(),(!_vm.listType)?_c('div',{staticClass:"no-list"},[_c('img',{staticClass:"no-icon",attrs:{"src":require("../assets/images/yhjuan.png"),"alt":""}}),_c('span',{staticClass:"no-tip"},[_vm._v("一大波超值卡券即将来袭，敬请期待~")])]):_vm._e(),_c('div',{staticClass:"footer-desc",on:{"click":_vm.toMyCoupon}},[_vm._v(" 查看全部已领卡券 "),_c('van-icon',{staticClass:"arrow-right",attrs:{"name":"arrow"}})],1)])])]),_c('h2',{staticClass:"goods-recommend"},[_vm._v(" "+_vm._s(_vm.hotCouponList.length > 0 ? "- 爆款商品推荐 -" : "")+" ")]),_c('div',{staticClass:"hot-coupon-box",style:({ 'padding-bottom': _vm.showMoreHot ? '0.36rem' : '' })},[_vm._l((_vm.hotCouponList.slice(0, _vm.limitLength)),function(item){return _c('div',{key:item.batchId,staticClass:"coupon-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"coupon-img",attrs:{"src":item.productType == 30
                ? require('../assets/images/bill.png')
                : item.promotionImg || _vm.defaultProductImg,"alt":""}}),_c('span',{staticClass:"coupon-tip"},[_vm._v("- 指定规格属性可用 -")])]),_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"coupon-name"},[_vm._v(" "+_vm._s(item.couponName)+" ")]),_c('div',{staticClass:"price-box"},[_c('img',{staticClass:"line",attrs:{"src":require("../assets/images/low_price.png"),"alt":""}}),_c('div',{staticClass:"old-price-box"},[_c('span',{staticClass:"left"},[_vm._v("￥"+_vm._s(_vm.mathManage.formatMoney(item.productSalePrice, 2)))]),_c('span',{staticClass:"right"},[_vm._v("￥"+_vm._s(_vm.mathManage.formatMoney(item.productSalePrice, 2)))])]),_c('div',{staticClass:"price-tip"},[_c('span',[_vm._v("券后价")]),_c('div',{staticClass:"coupon-new-price"},[_vm._v(" ￥"+_vm._s(item.couponAfterPrice)+" "),_c('van-icon',{staticClass:"arrow",attrs:{"name":"play"}})],1)])]),_c('div',{staticClass:"action-box"},[_c('div',{staticClass:"left-btn"},[_vm._v(" ￥"+_vm._s(item.amount)),_c('span',{staticClass:"tip"},[_vm._v("优惠券")])]),_c('div',{class:[
                'right-btn',
                item.reviceStatus == 20 && 'toUse',
                item.reviceStatus == 40 && 'isUse' ],on:{"click":function($event){return _vm.takeCoupon(item)}}},[_vm._v(" "+_vm._s(_vm.couponStatus[item.reviceStatus])+" ")])])])])}),(!_vm.showMoreHot && _vm.hotCouponList.length >= 4)?_c('div',{staticClass:"more-product",on:{"click":function($event){_vm.showMoreHot = true}}},[_vm._v(" 查看更多爆款商品 "),_c('van-icon',{staticClass:"arrow-down",attrs:{"name":"arrow-down"}})],1):_vm._e()],2)]),_c('van-sticky',[_c('div',{staticClass:"tabs-box"},[_c('van-tabs',{on:{"click":_vm.changeTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabList),function(item){return _c('van-tab',{key:item.id,attrs:{"title":item.cateName,"name":item.id}})}),1)],1)]),_c('div',{staticClass:"coupon-box"},[(_vm.couponList.length > 0)?_c('coupon-list',{attrs:{"list":_vm.couponList},on:{"getList":_vm.getListByTab}}):_c('div',{staticClass:"no-coupon"},[_vm._v("抱歉，当前暂无卡券")])],1),_c('card-mobile-view',{attrs:{"couponData":_vm.couponData,"productType":_vm.productType},on:{"onCancel":_vm.onCancel},model:{value:(_vm.showViewMobile),callback:function ($$v) {_vm.showViewMobile=$$v},expression:"showViewMobile"}}),_c('login-box',{on:{"handleCancel":_vm.handleCancelLogin,"onOk":_vm.loginOk},model:{value:(_vm.showLogin),callback:function ($$v) {_vm.showLogin=$$v},expression:"showLogin"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"serch-title"},[_c('div',{staticClass:"big-title"},[_vm._v("领券下单更优惠")]),_c('div',{staticClass:"big-desc"},[_vm._v("超/值/福/利/天/天/有")])])}]

export { render, staticRenderFns }