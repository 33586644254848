<template>
  <div class="coupon-center">
    <div class="search-bar">
      <van-search
        @click="toSearch"
        class="my-search"
        v-model="value"
        background="transparent"
        placeholder="输入关键词/商品名称"
      />
      <div class="serch-title">
        <div class="big-title">领券下单更优惠</div>
        <div class="big-desc">超/值/福/利/天/天/有</div>
      </div>
    </div>
    <!-- 黄色区域 -->
    <div class="hot-goods">
      <div class="hot-content">
        <!-- <svg-icon iconClass="coupon_center" class="coupon-center" /> -->
        <img
          src="../assets/images/coupon-title-icon.png"
          class="coupon-title-icon"
          alt=""
        />
        <div class="goods-list">
          <div class="list-content">
            <div class="each-div" v-if="listType == 1">
              <div class="top-coupon">
                <div class="goods-price">
                  <span
                    style="
                      font-size: 0.28rem;
                      padding-top: 0.1rem;
                      margin-right: 0.04rem;
                    "
                    >¥</span
                  >
                  <span>{{ limitCouponList[0].amount }}</span>
                </div>
                <div class="goods-spec">
                  <div class="names">
                    {{ limitCouponList[0].couponName }}
                  </div>
                  <div class="spec">指定规格属性可用</div>
                </div>
                <div
                  :class="[
                    'isGet',
                    limitCouponList[0].reviceStatus == 40 && 'isUse',
                  ]"
                  @click="takeCoupon(limitCouponList[0])"
                >
                  {{ couponStatus[limitCouponList[0].reviceStatus] }}
                </div>
              </div>
              <div class="other-coupon">
                <div
                  class="coupon-item"
                  v-for="item in limitCouponList.slice(1, 4)"
                  :key="item.batchId"
                >
                  <div class="tag">限时钜惠</div>
                  <div class="item-top">
                    <img
                      v-if="item.reviceStatus == 20"
                      class="get-icon"
                      src="../assets/images/water_mark1.png"
                      alt=""
                    />
                    <img
                      v-if="item.reviceStatus == 40"
                      class="get-icon"
                      src="../assets/images/used2.png"
                      alt=""
                    />
                    <div class="price-box">
                      <span class="unit">￥</span>
                      <span class="price">{{ item.amount }}</span>
                    </div>
                    <div class="coupon-name one-line-hidden">
                      {{ item.couponName }}
                    </div>
                    <div class="coupon-tip">指定规格属性可用</div>
                  </div>
                  <div class="item-bottom">
                    <span
                      :class="[
                        'action-btn',
                        item.reviceStatus == 40 && 'isUse',
                      ]"
                      @click="takeCoupon(item)"
                      >{{ couponStatus[item.reviceStatus] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="each-div" v-if="listType == 2">
              <div
                class="top-coupon list-type-2"
                v-for="item in limitCouponList"
                :key="item.batchId"
              >
                <div class="goods-price">
                  <span class="unit">¥</span>
                  <span>{{ item.amount }}</span>
                </div>
                <div class="goods-spec">
                  <div class="names">
                    {{ item.couponName }}
                  </div>
                  <div class="spec">指定规格属性可用</div>
                </div>
                <div
                  :class="['isGet', item.reviceStatus == 40 && 'isUse']"
                  @click="takeCoupon(item)"
                >
                  {{ couponStatus[item.reviceStatus] }}
                </div>
              </div>
            </div>
            <div class="no-list" v-if="!listType">
              <img class="no-icon" src="../assets/images/yhjuan.png" alt="" />
              <span class="no-tip">一大波超值卡券即将来袭，敬请期待~</span>
            </div>
            <div class="footer-desc" @click="toMyCoupon">
              查看全部已领卡券 <van-icon class="arrow-right" name="arrow" />
            </div>
          </div>
        </div>
      </div>
      <!--爆款商品推荐  -->
      <h2 class="goods-recommend">
        {{ hotCouponList.length > 0 ? "- 爆款商品推荐 -" : "" }}
      </h2>
      <!-- 列表区域 -->
      <div
        class="hot-coupon-box"
        :style="{ 'padding-bottom': showMoreHot ? '0.36rem' : '' }"
      >
        <div
          class="coupon-item"
          v-for="item in hotCouponList.slice(0, limitLength)"
          :key="item.batchId"
        >
          <div class="item-left">
            <img
              class="coupon-img"
              :src="
                item.productType == 30
                  ? require('../assets/images/bill.png')
                  : item.promotionImg || defaultProductImg
              "
              alt=""
            />
            <span class="coupon-tip">- 指定规格属性可用 -</span>
          </div>
          <div class="item-right">
            <div class="coupon-name">
              {{ item.couponName }}
            </div>
            <div class="price-box">
              <img class="line" src="../assets/images/low_price.png" alt="" />
              <div class="old-price-box">
                <span class="left"
                  >￥{{
                    mathManage.formatMoney(item.productSalePrice, 2)
                  }}</span
                >
                <span class="right"
                  >￥{{
                    mathManage.formatMoney(item.productSalePrice, 2)
                  }}</span
                >
              </div>
              <div class="price-tip">
                <span>券后价</span>
                <div class="coupon-new-price">
                  ￥{{ item.couponAfterPrice }}
                  <van-icon class="arrow" name="play" />
                </div>
              </div>
            </div>
            <div class="action-box">
              <div class="left-btn">
                ￥{{ item.amount }}<span class="tip">优惠券</span>
              </div>
              <div
                :class="[
                  'right-btn',
                  item.reviceStatus == 20 && 'toUse',
                  item.reviceStatus == 40 && 'isUse',
                ]"
                @click="takeCoupon(item)"
              >
                {{ couponStatus[item.reviceStatus] }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!showMoreHot && hotCouponList.length >= 4"
          class="more-product"
          @click="showMoreHot = true"
        >
          查看更多爆款商品
          <van-icon class="arrow-down" name="arrow-down" />
        </div>
      </div>
    </div>
    <van-sticky>
      <div class="tabs-box">
        <van-tabs v-model="activeTab" @click="changeTab">
          <van-tab
            v-for="item in tabList"
            :key="item.id"
            :title="item.cateName"
            :name="item.id"
          ></van-tab>
        </van-tabs>
      </div>
    </van-sticky>
    <div class="coupon-box">
      <coupon-list
        v-if="couponList.length > 0"
        :list="couponList"
        @getList="getListByTab"
      />
      <div v-else class="no-coupon">抱歉，当前暂无卡券</div>
    </div>
    <card-mobile-view
      v-model="showViewMobile"
      :couponData="couponData"
      :productType="productType"
      @onCancel="onCancel"
    />
    <login-box
      v-model="showLogin"
      @handleCancel="handleCancelLogin"
      @onOk="loginOk"
    />
  </div>
</template>

<script>
import { Search, Sticky } from "vant";
import "@/assets/images/svg/coupon_center.svg";
import { Icon } from "vant";
import { mapState, mapActions } from "vuex";
import {
  getCouponCateList,
  getCouponListByTagId,
  getCouponListBySearch,
  ReceiveCoupon,
} from "../api/common";
import CouponList from "@/components/CouponList.vue";
import { couponStatus } from "@/utils/dictionary";
import mathManage from "@/utils/mathManage";
import CardMobileView from "@/components/CardMobileView.vue";
import LoginBox from "@/components/LoginBox.vue";
import debounce from "lodash/debounce";
export default {
  name: "CouponCenter",
  components: {
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    CouponList,
    CardMobileView,
    LoginBox,
  },
  data() {
    return {
      couponStatus,
      mathManage,
      value: "",
      limitCouponList: [], // 限时抢购
      hotCouponList: [], // 爆款推荐
      showMoreHot: false,
      activeTab: "",
      tabList: [
        {
          id: "",
          cateName: "全部",
        },
      ],
      couponList: [],
      showViewMobile: false,
      couponData: {},
      productType: "",
      showLogin: false,
      actionFn: null,
    };
  },
  computed: {
    ...mapState(["defaultProductImg", "isLogin", "isInWx"]),
    listType: {
      get() {
        if (this.limitCouponList.length >= 4) {
          return 1;
        }
        if (this.limitCouponList.length == 0) {
          return 0;
        }
        return 2;
      },
      set() {},
    },
    limitLength: {
      get() {
        return this.showMoreHot ? 6 : 3;
      },
      set() {},
    },
  },
  created() {
    document.title = "领券中心";
    this.$loading("加载中...");
    this.productCate();
    this.getHotCouponList(7);
    this.init();
  },
  methods: {
    ...mapActions(["dispatchGetCardCount"]),

    handleCancelLogin() {
      this.showLogin = false;
      this.actionFn = null;
    },

    loginOk() {
      this.showLogin = false;
      this.actionFn && this.actionFn();
      this.actionFn = null;
    },

    init() {
      this.getHotCouponList(6);
      this.getList();
    },

    // 卡券分类列表领取卡券成功后执行操作
    getListByTab(batchId) {
      this.init();
      this.hotCouponList = this.hotCouponList.map((item) => {
        return {
          ...item,
          reviceStatus: batchId == item.batchId ? 20 : item.reviceStatus,
        };
      });
    },

    toSearch() {
      this.$router.push("/mall/searchCoupon");
    },

    // 根据标签查询优惠券列表（限时闪购，爆款推荐）
    async getHotCouponList(tagId) {
      try {
        const res = await getCouponListByTagId({ tagId });
        this.$toast.clear();
        if (res && res.code == 0) {
          if (tagId == 6) {
            this.limitCouponList = res.data.list || [];
          } else {
            this.hotCouponList = res.data.list || [];
          }
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },

    // 分类
    async productCate() {
      const res = await getCouponCateList();
      if (res && res.code == 0) {
        let data = res.data.list || [];
        this.tabList = [...this.tabList, ...data];
      } else {
        this.$message.error(res.message);
      }
    },

    // 切换tab
    changeTab(activeTab) {
      this.activeTab = activeTab;
      this.$nextTick(() => {
        this.getList();
      });
    },

    // 查看已领优惠券
    toMyCoupon() {
      if (!this.isLogin && !this.isInWx) {
        this.showLogin = true;
        this.actionFn = this.toMyCoupon;
        return;
      }
      this.$router.push("/mall/ticket");
    },

    async getList() {
      const res = await getCouponListBySearch({
        CateId: this.activeTab,
      });
      if (res && res.code == 0) {
        this.couponList = res.data.list || [];
      } else {
        this.$toast(res.message);
      }
    },

    // 领取/使用优惠券
    takeCoupon(data) {
      this.$nextTick(() => {
        const { reviceStatus, upperProductId = "", productType } = data;
        if (!this.isLogin) {
          if (this.isInWx) {
            this.$wxLogin();
            return;
          }
          this.showLogin = true;
          this.actionFn = this.init;
          return;
        }
        // 10：立即领取  20：去使用  30：继续领取  40：已领取
        if (reviceStatus == 40) return;
        if (reviceStatus == 20) {
          // 话费
          if (productType == 30 || productType == 40) {
            this.$router.push(
              `/mall/phoneBill?productType=${productType}&productId=${upperProductId}`
            );
          } else {
            this.$router.push(
              `/mall/ProductDetails?productId=${upperProductId}`
            );
          }
        }
        if (reviceStatus == 10 || reviceStatus == 30) {
          this.reviceCoupon(data);
        }
      });
    },

    // 领券
    reviceCoupon: debounce(async function (data = {}) {
      if (!data.batchId) {
        this.$toast("卡券异常！");
        return;
      }
      this.$loading("请稍等...");
      try {
        const res = await ReceiveCoupon({
          batchId: data.batchId,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.dispatchGetCardCount();
          this.couponData = { productId: data.upperProductId };
          this.productType = data.productType;
          this.init();
          this.hotCouponList = this.hotCouponList.map((item) => {
            return {
              ...item,
              reviceStatus:
                data.batchId == item.batchId ? 20 : item.reviceStatus,
            };
          });
          this.$toast("领取成功！");
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
        const { status } = error.response || {};
        if (status == 401) {
          if (this.isInWx) {
            this.$wxLogin();
            return;
          }
          this.showLogin = true;
        }
      }
    }, 500),

    onCancel() {
      this.showViewMobile = false;
      this.productType = "";
      this.couponData = {};
    },
  },
};
</script>

<style scoped lang="less">
.coupon-center {
  overflow-y: auto;
  height: 100%;
  background-color: #f5f5f5;

  .search-bar {
    padding-top: 1rem;
    height: 4rem;
    background-image: url(../assets/images/coupon_center_bgc.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    .my-search {
      padding: 0 0;
      margin: 0 0.32rem;
      .van-search__content {
        background: rgba(255, 255, 255, 0.3);
        /deep/.van-field__control {
          &::placeholder {
            color: #ffffff;
          }
        }
        /deep/.van-icon {
          color: #ffffff;
        }
      }
    }
    .serch-title {
      font-size: 0.4rem;
      font-family: AlibabaPuHuiTi-Heavy, AlibabaPuHuiTi;
      font-weight: 800;
      color: #ffffff;
      padding-top: 0.48rem;
      > div {
        margin-left: 0.82rem;
      }
      .big-desc {
        margin-top: 0.12rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.3);
        letter-spacing: 1px;
      }
    }
  }
  .hot-goods {
    background-color: #f84c3e;
    .hot-content {
      width: 6.86rem;
      // height: 5.24rem;
      background: #fef0c7;
      border-radius: 0.16rem;
      margin: 0 0.32rem;
      position: relative;
      padding: 0 0.24rem;
      padding-bottom: 0.2rem;

      .footer-desc {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #983508;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;

        .arrow-right {
          margin-left: 0.08rem;
          font-size: 0.16rem;
        }
      }
      .coupon-title-icon {
        width: 3.46rem;
        height: 0.64rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .goods-list {
        padding-top: 0.88rem;
        .list-content {
          .top-coupon {
            height: 1.28rem;
            background-image: url(../assets/images/coupon_top.png);
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            padding-left: 0.48rem;
            position: relative;
            &::before {
              top: 0;
              left: 0;
              position: absolute;
              display: inline-block;
              content: "限时钜惠";
              background-color: red;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.18rem;
              width: 0.72rem;
              height: 0.22rem;
              background: linear-gradient(90deg, #ff630a 0%, #ff7121 100%);
              border-radius: 0.12rem 0rem 0.12rem 0rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .goods-price {
              font-size: 0.56rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #ee4410;
              flex: 0 0 1.6rem;
              display: flex;
              align-items: center;
            }
            .goods-spec {
              flex: 0 0 2rem;
              .names {
                font-size: 0.22rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #013774;
                display: -webkit-box;
                /* 弹性盒旧式语法 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /* 限制两行 */
                overflow: hidden;
              }
              .spec {
                font-size: 0.18rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #013774;
              }
            }
            .isGet {
              background: #ffe38c;
              box-shadow: 0rem 0rem 0.04rem 0rem rgba(255, 255, 255, 0.5);
              border-radius: 0.2rem;
              font-size: 0.22rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #983508;
              width: 1.28rem;
              height: 0.36rem;
              // line-height: 0.36rem;
              // text-align: center;
              position: absolute;
              right: 0.28rem;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              align-items: center;
              justify-content: center;

              &.isUse {
                background: #fff5d5;
                color: #ffc7a1;
              }
            }
          }

          .other-coupon {
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.12rem;

            .coupon-item {
              width: 2.04rem;
              height: 2.28rem;
              border-radius: 0.16rem;
              overflow: hidden;
              background-image: url("../assets/images/coupon_content.png");
              background-size: 100% 100%;
              position: relative;

              .tag {
                position: absolute;
                top: 0rem;
                left: 0rem;
                background: linear-gradient(90deg, #ff630a 0%, #ff7121 100%);
                border-radius: 0.12rem 0rem 0.12rem 0rem;
                padding: 0.02rem 0.1rem;
                text-align: center;
                overflow: hidden;
                font-size: 0.14rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }

              .item-top {
                height: 1.48rem;
                padding: 0.08rem;
                box-sizing: border-box;
                position: relative;

                .get-icon {
                  position: absolute;
                  right: 0.1rem;
                  top: 0.1rem;
                  width: 0.64rem;
                  height: 0.64rem;
                  z-index: 1;
                }

                .price-box {
                  width: 100%;
                  height: 0.5rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 0.2rem;
                  position: relative;
                  z-index: 2;

                  .unit {
                    font-size: 0.28rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 600;
                    color: #ee4410;
                    padding-top: 0.1rem;
                  }

                  .price {
                    font-size: 0.44rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #ee4410;
                  }
                }

                .coupon-name {
                  font-size: 0.18rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #013774;
                  width: 80%;
                  text-align: center;
                  overflow: hidden;
                  margin: 0 auto;
                  margin-top: 0.04rem;
                }

                .coupon-tip {
                  font-size: 0.18rem;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #013774;
                  text-align: center;
                  margin-top: 0.02rem;
                }
              }

              .item-bottom {
                height: 0.8rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .action-btn {
                  background: #ffe38c;
                  box-shadow: 0rem 0rem 0.04rem 0rem rgba(255, 255, 255, 0.5);
                  border-radius: 0.2rem;
                  font-size: 0.22rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #983508;
                  padding: 0.02rem 0.22rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 0.08rem;

                  &.isUse {
                    background: #fff5d5;
                    color: #ffc7a1;
                  }
                }
              }
            }
          }

          .list-type-2 {
            margin-bottom: 0.16rem;
          }
        }
      }

      .no-list {
        height: 4rem;
        background: #fef0c7;
        border-radius: 0.16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .no-icon {
          width: 2.6rem;
          height: 2rem;
        }

        .no-tip {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d07a54;
          margin-top: 0.2rem;
        }
      }
    }
  }
  .goods-recommend {
    font-size: 0.32rem;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #fffeff;
    padding: 0.28rem 0 0.2rem;
    text-align: center;
  }

  .hot-coupon-box {
    padding: 0 0.32rem 0.16rem;
    box-sizing: border-box;

    .coupon-item {
      padding: 0.24rem 0.24rem 0.16rem 0.24rem;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 0.16rem;
      margin-bottom: 0.16rem;
      display: flex;

      .item-left {
        width: 2.28rem;
        margin-right: 0.32rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .coupon-img {
          width: 100%;
          height: 1.36rem;
          border-radius: 0.08rem;
        }

        .coupon-tip {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d1d1d1;
          margin-top: 0.2rem;
        }
      }

      .item-right {
        flex: 1;

        .coupon-name {
          font-size: 0.25rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          display: -webkit-box;
          /* 弹性盒旧式语法 */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* 限制两行 */
          overflow: hidden;
        }

        .price-box {
          position: relative;
          margin-top: 0.18rem;

          .line {
            width: 100%;
            height: 0.4rem;
            position: absolute;
            z-index: 2;
          }

          .old-price-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 0.1rem;

            span {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #d1d1d1;
            }
          }

          .price-tip {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fd3940;
            text-align: center;
            padding-top: 0.02rem;
            position: relative;

            .coupon-new-price {
              padding: 0 0.12rem;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.24rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              position: absolute;
              left: 50%;
              top: 0;
              transform: translate(-50%, -140%);
              z-index: 5;
              background: linear-gradient(90deg, #fe5a34 0%, #fd3940 100%);
              border-radius: 0.06rem;

              .arrow {
                display: block;
                position: absolute;
                bottom: -0.1rem;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
                color: #fd3940;
              }
            }
          }
        }

        .action-box {
          display: flex;
          align-items: center;
          margin-top: 0.08rem;

          .left-btn {
            flex: 1;
            margin-right: 0.08rem;
            height: 0.48rem;
            background: #fee7e5;
            border-radius: 0.04rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fd3b3f;

            .tip {
              font-size: 0.2rem;
            }
          }

          .right-btn {
            flex: 1;
            height: 0.48rem;
            background: linear-gradient(90deg, #fe5a34 0%, #fd3940 100%);
            border-radius: 0.04rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            &.toUse {
              background: linear-gradient(90deg, #ff630a 0%, #ff7121 100%);
            }
            &.isUse {
              background: #fff;
              border: 0.02rem solid #d3d3d3;
              color: #d3d3d3;
            }
          }
        }
      }
    }
  }

  .more-product {
    width: 4rem;
    height: 0.56rem;
    border-radius: 0.39rem;
    border: 0.01rem solid #ffffff;
    margin-top: 0.08rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin: 0 auto;
    margin-bottom: 0.16rem;

    .arrow-down {
      margin-left: 0.08rem;
    }
  }

  .tabs-box {
    overflow: hidden;
    box-shadow: 0 0.08rem 0.09rem rgba(0, 0, 0, 0.06);
  }

  .coupon-box {
    min-height: 100vh;

    .no-coupon {
      min-height: 4rem;
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bdbdbd;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
